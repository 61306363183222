import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { ForgeIcon, ForgeFloatingActionButton } from '@tylertech/forge-react';

import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import ScrollToTop from 'common/components/ScrollToTop';
import { addPresentationModeAnchors } from '../lib/TableOfContentsUtils';

/**
 * @class PresentationMode
 * @description
 * Adds functionality to the presentation mode template.
 * This includes:
 * - Taking a preview-mode story and showing blocks one at a time.
 * - Paging between individual blocks
 * - Resizing the content to fit appropriately into the screen size.
 * - When not presenting:
 *   - shows a scroll to top button
 *   - shows a print button which opens a ?print=true tab
 */
export default function PresentationMode() {
  const renderScrollToTopButton = () => {
    const minPageScrolls = 3;
    const offsetHeight = window.innerHeight * minPageScrolls;
    return <ScrollToTop floatingActionButtonProps={{ mini: true }} pageYOffsetThreshold={offsetHeight} />;
  };

  const renderPrintFromViewButton = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const alreadyPrinting = urlParams.get('print');
    const printFromViewEnabled = FeatureFlags.value('enable_print_from_view_story');
    // Skip print button if we're on ?print=true, or not on FF
    if (alreadyPrinting || !printFromViewEnabled) return null;

    const printUrl = `${window.location.href}?print=true`;
    return (
      <div className="btn-print-from-view">
        <ForgeFloatingActionButton mini>
          <button
            type="button"
            aria-label={I18n.t('preview_mode.print_from_view')}
            onClick={() => window.open(printUrl)}
          >
            <ForgeIcon name="print" aria-hidden="true"></ForgeIcon>
          </button>
        </ForgeFloatingActionButton>
      </div>
    );
  };

  const renderPresentationModeButton = () => {
    return (
      <div className="btn-presentation-mode">
        <ForgeFloatingActionButton mini>
          <button
            type="button"
            aria-label={I18n.t('preview_mode.presentation_mode.start')}
            onClick={togglePresentationMode}
          >
            <ForgeIcon
              name="presentation_play"
              aria-hidden="true"
              external
              external-type="extended"
            ></ForgeIcon>
          </button>
        </ForgeFloatingActionButton>
      </div>
    );
  };

  const renderPresentationControls = () => {
    return (
      // Presentation controls start hidden, revealed in togglePresentationMode
      <div className="presentation-controls hidden">
        <div className="btn-presentation-previous">
          <ForgeFloatingActionButton mini>
            <button
              type="button"
              aria-label={I18n.t('preview_mode.presentation_mode.previous')}
              onClick={pagePrevious}
            >
              <ForgeIcon name="chevron_left" aria-hidden="true"></ForgeIcon>
            </button>
          </ForgeFloatingActionButton>
        </div>
        <div className="btn-presentation-next">
          <ForgeFloatingActionButton mini>
            <button
              type="button"
              aria-label={I18n.t('preview_mode.presentation_mode.next')}
              onClick={pageNext}
            >
              <ForgeIcon name="chevron_right" aria-hidden="true"></ForgeIcon>
            </button>
          </ForgeFloatingActionButton>
        </div>
        <div className="btn-presentation-close">
          <ForgeFloatingActionButton mini>
            <button
              type="button"
              aria-label={I18n.t('preview_mode.presentation_mode.exit')}
              onClick={togglePresentationMode}
            >
              <ForgeIcon name="close" aria-hidden="true"></ForgeIcon>
            </button>
          </ForgeFloatingActionButton>
        </div>
      </div>
    );
  };

  const renderWatchAssetButton = () => {
    if (
      FeatureFlags.value('enable_screenshot_subscriptions') &&
      FeatureFlags.value('enable_embedded_schedule_send')
    ) {
      return <div className="btn-watch-asset-button" />;
    }
  };

  ReactDOM.render(
    <>
      <div className="viewing-controls">
        {renderScrollToTopButton()}
        {renderPrintFromViewButton()}
        {renderWatchAssetButton()}
        {FeatureFlags.value('enable_present_in_stories') ? renderPresentationModeButton() : null}
      </div>
      {renderPresentationControls()}
    </>,
    document.querySelector('.actions-bar')
  );

  const blocks = Array.from(document.querySelectorAll('.block'));
  const userStory = document.querySelector('.user-story');
  const footer = document.querySelector('#site-chrome-footer');
  const viewingControls = document.querySelector('.viewing-controls');
  const presentationControls = document.querySelector('.presentation-controls');
  const previous = document.querySelector('.btn-presentation-previous');

  document.documentElement.addEventListener('keyup', pageOrClose);

  attachPageIndexes();
  presentOnStart();

  function attachPageIndexes() {
    var index = 0;

    blocks.forEach(function (block) {
      if (notBlacklisted(block)) {
        block.setAttribute('data-page-index', index++);
      }
    });

    if (userStory.querySelectorAll('.component-html-table-of-contents').length > 0) {
      addPresentationModeAnchors(blocks);
    }
  }

  function removeContentLinkEventHandler() {
    _.each(
      // IE doesn't implement forEach on NodeLists.
      userStory.querySelectorAll('.component-html-table-of-contents a'),
      (contentLink) => contentLink.removeEventListener('click', skipToAnchor)
    );
  }

  function presentOnStart() {
    var searchParameters = {};
    var search = window.location.search[0] === '?' ? window.location.search.slice(1) : window.location.search;

    var keyValues = _.compact(search.split('&'));

    _.each(keyValues, (keyValue) => {
      var [key, value] = keyValue.split('=');
      searchParameters[key] = value !== 'false';
    });

    if (searchParameters.present) {
      togglePresentationMode();
    }
  }

  function togglePresentationMode() {
    if (userStory.classList.contains('presentation-mode')) {
      enableLinearMode();
    } else {
      _.each(
        // IE doesn't implement forEach on NodeLists.
        userStory.querySelectorAll('.component-html-table-of-contents a'),
        (contentLink) => contentLink.addEventListener('click', skipToAnchor)
      );

      userStory.classList.add('presentation-mode');
      footer.classList.add('presentation-mode');
      viewingControls.classList.add('hidden');
      presentationControls.classList.remove('hidden');
      previous.focus();

      blocks.forEach(function (block) {
        var index = parseInt(block.getAttribute('data-page-index'), 10);
        if (index !== 0) {
          block.classList.add('hidden');
        } else {
          block.classList.remove('hidden');
        }
      });
    }
  }

  function enableLinearMode() {
    userStory.classList.remove('presentation-mode');
    footer.classList.remove('presentation-mode');
    viewingControls.classList.remove('hidden');
    presentationControls.classList.add('hidden');

    blocks.forEach(function (block) {
      block.classList.remove('hidden');
    });

    removeContentLinkEventHandler();
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;

    $('.socrata-visualization').trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  }

  function screenReaderAnnouncement(text) {
    var el = document.createElement('div');
    var id = 'sr-speak-' + Date.now();
    el.setAttribute('id', id);
    el.setAttribute('aria-live', 'polite');
    el.classList.add('sr-only');
    document.body.appendChild(el);

    window.setTimeout(function () {
      document.getElementById(id).innerHTML = text;
    }, 100);

    window.setTimeout(function () {
      document.body.removeChild(document.getElementById(id));
    }, 1000);
  }

  function switchToPage(newPageIndex, defaultPageIndex) {
    var visible = document.querySelector('.block:not(.hidden)');
    var newVisible = document.querySelector(`.block[data-page-index="${newPageIndex}"]`);

    newVisible = newVisible || document.querySelector(`.block[data-page-index="${defaultPageIndex}"]`);
    if (!newVisible) {
      return;
    }

    visible.classList.add('hidden');
    newVisible.classList.remove('hidden');

    screenReaderAnnouncement(I18n.t('preview_mode.presentation_mode.page_changed'));
    // Re-render any visualizations in the newly visible page
    $(newVisible).find('.socrata-visualization').trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  }

  function pageNext() {
    var visible = document.querySelector('.block:not(.hidden)');
    var nextIndex = parseInt(visible.getAttribute('data-page-index'), 10) + 1;
    switchToPage(nextIndex, 0);
  }

  function pagePrevious() {
    var visible = document.querySelector('.block:not(.hidden)');
    var previousIndex = parseInt(visible.getAttribute('data-page-index'), 10) - 1;

    var pageable = document.querySelectorAll('.block[data-page-index]');
    switchToPage(previousIndex, pageable.length - 1);
  }

  function pageOrClose(event) {
    var key = event.charCode || event.keyCode;
    var isPresenting = document.querySelector('.presentation-mode');

    if (isPresenting) {
      // 27 == ESC, 37 == <-, 39 == ->
      // 33 = PageUp, 34 = PageDn
      if (key === 27) {
        enableLinearMode();
      } else if (key === 37 || key === 33) {
        pagePrevious();
      } else if (key === 39 || key === 34) {
        pageNext();
      }
    }
  }

  function skipToAnchor() {
    const destination = _.last(this.href.split('#'));
    const destBlock = userStory.querySelector(`[data-heading-ids*=",${destination},"]`);
    if (destBlock) {
      const destIndex = parseInt(destBlock.getAttribute('data-page-index'), 10);
      switchToPage(destIndex);
    }
  }

  function notBlacklisted(element) {
    return element.dataset.hasOwnProperty('presentable') && element.dataset.presentable !== 'false';
  }
}
