// Linter migration tech debt.
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */

import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { assert, assertHasProperties, assertHasProperty } from 'common/assertions';
import I18n from 'common/i18n';

import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import './shared/componentBase';

import { VisualizationCanvasProps } from './types';
import { VizCanvasDatasetConfig } from 'types';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';
import ComponentVizCanvas, { ComponentVizCanvasProps } from './componentVizCanvas/ComponentVizCanvas';

$.fn.componentSocrataVisualizationVizCanvas = componentSocrataVisualizationVizCanvas;

/*
  Component format:
  {
    type: "socrata.visualization.vizCanvas",
    value: {
      dataset: {
        datasetUid: ...
        vifId: ...
      }
    }
  }
*/

export const resizableProps = () => {
  return {
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    }
  };
};

export default function componentSocrataVisualizationVizCanvas(props: VisualizationCanvasProps) {
  _.defaults(props, resizableProps(), {
    useMetadataCache: true, // We want to cache requests for metadata, but not during test runs
    additionalFilters: {}, // Redux would give us this empty object, but currently this can be called
    parameterOverrides: {} // from AssetSelectorRenderer, which doesn't use Redux
  });

  const $this = $(this);
  const { componentData, editMode } = props;

  assertHasProperty(componentData, 'type');
  assert(
    componentData.type === 'socrata.visualization.vizCanvas',
    `componentSocrataVisualizationVizCanvas: Unsupported component type ${componentData.type}`
  );

  if ($this.children().length === 0) {
    _renderTemplate($this, props);
  }

  _updateVisualization($this, props);

  if (!shouldUseReactComponentBase(editMode)) {
    $this.componentBase(props);
  }

  return $this;
}

function _renderTemplate($element: JQuery, props: VisualizationCanvasProps) {
  const { componentData } = props;

  assertHasProperties(componentData, 'value.dataset.datasetUid', 'value.dataset.vifId');

  const { type } = componentData;
  const classes = StorytellerUtils.typeToClassesForComponentType(type);
  const $componentContent = $('<div>', { class: 'component-content' });

  $element
    .addClass(classes)
    .on('destroy', () => {
      $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
    })
    .append($componentContent);
}

function _updateVisualization($element: JQuery, props: VisualizationCanvasProps) {
  const { componentData, useMetadataCache } = props;

  assertHasProperties(componentData, 'value.dataset.datasetUid', 'value.dataset.vifId');

  function _renderVisualization(visualizationConfig: VizCanvasDatasetConfig) {
    const vizCanvasProps: ComponentVizCanvasProps = {
      visualizationConfig,
      useMetadataCache,
      jQueryErrorCallback: _renderError
    };

    ReactDOM.render(
      <Provider store={StorytellerReduxStore}>
        <ComponentVizCanvas {...vizCanvasProps} />
      </Provider>,
      $componentContent[0]
    );
  }

  function _renderError(shouldError: boolean) {
    $('.socrata-visualization-error', $element).remove();

    if (!shouldError) return;

    const errorMessage = I18n.t('editor.viz_canvas.errors.status');
    const containerHeight = `${_.get(
      componentData,
      'value.layout.height',
      props.resizeOptions?.minHeight
    )}px`;
    // Fake the visualization's internal error rendering
    const $errorMessageElement = $(`
      <div style="height: ${containerHeight}" class="socrata-visualization socrata-visualization-error">
        <div class="socrata-visualization-error-container error light">
          <span class="socrata-visualization-error-message text">
            ${errorMessage}
          </span>
        </div>
      </div>
    `);

    $componentContent.append($errorMessageElement);
  }

  const visualizationConfig = componentData.value.dataset!;
  const $componentContent = $element.find('.component-content');
  // we need to add this for withComponentLayoutHeight to work
  $componentContent.addClass('story-viz-react');

  _renderVisualization(visualizationConfig);
}
