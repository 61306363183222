/**
 * Helpers that allow React to pull props from jQuery plugins.
 */

import { ComponentType, JQueryPlugin, BlockComponent } from 'types';

// @ts-expect-error
import { resizableProps as embeddedHtmlProps } from 'lib/components/block-component-renderers/componentEmbeddedHtml';
import { resizableProps as heroProps } from 'lib/components/block-component-renderers/componentHero';
import { resizableProps as measureProps } from 'lib/components/block-component-renderers/componentMeasure';
// @ts-expect-error
import { resizableProps as classicVizProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationClassic';
import { resizableProps as featureMapProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationFeatureMap';
import { resizableProps as inSituProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationInSitu';
import { resizableProps as mapProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationMap';
import { resizableProps as regionMapProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationRegionMap';
import { resizableProps as tableProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationTable';
import { resizableProps as vizCanProps } from 'lib/components/block-component-renderers/componentSocrataVisualizationVizCanvas';

interface ResizableProps {
  resizeSupported: boolean;
  resizeOptions?: {
    minHeight?: number;
  };
  defaultHeight?: number;
}

export const getResizableComponentProps = (
  type: JQueryPlugin,
  componentData: BlockComponent
): ResizableProps => {
  switch (type) {
    case JQueryPlugin.CLASSIC_VIZ:
      return classicVizProps();
    case JQueryPlugin.EMBEDDED_HTML:
      return embeddedHtmlProps(componentData);
    case JQueryPlugin.FEATURE_MAP:
      return featureMapProps();
    case JQueryPlugin.HERO:
      return heroProps(componentData);
    case JQueryPlugin.INSITU:
      return inSituProps(componentData);
    case JQueryPlugin.MAP:
      return mapProps();
    case JQueryPlugin.MEASURE:
      return measureProps(componentData);
    case JQueryPlugin.REGION_MAP:
      return regionMapProps();
    case JQueryPlugin.TABLE:
      return tableProps();
    case JQueryPlugin.VIZ_CANVAS:
      return vizCanProps();
    default:
      return {
        resizeSupported: false
      };
  }
};

export const getComponentRenderer = (type: ComponentType): JQueryPlugin => {
  switch (type) {
    case ComponentType.HTML:
    case ComponentType.HTML_TABLE_OF_CONTENTS:
      return JQueryPlugin.HTML;
    case ComponentType.SPACER:
      return JQueryPlugin.SPACER;
    case ComponentType.HORIZONTAL_RULE:
      return JQueryPlugin.HORIZONTAL_RULE;
    case ComponentType.ASSET_SELECTOR:
      return JQueryPlugin.ASSET_SELECTOR;
    case ComponentType.AUTHOR:
      return JQueryPlugin.AUTHOR;
    case ComponentType.IMAGE:
      return JQueryPlugin.IMAGE;
    case ComponentType.HERO:
      return JQueryPlugin.HERO;
    case ComponentType.GLOBAL_FILTER:
      return JQueryPlugin.GLOBAL_FILTER;
    case ComponentType.STORY_TILE:
    case ComponentType.STORY_WIDGET:
      return JQueryPlugin.STORY_TILE;
    case ComponentType.MEASURE_CARD:
    case ComponentType.MEASURE_CHART:
      return JQueryPlugin.MEASURE;
    case ComponentType.YOUTUBE:
      return JQueryPlugin.YOUTUBE;
    case ComponentType.CLASSIC_VIZ:
      return JQueryPlugin.CLASSIC_VIZ;
    case ComponentType.REGION_MAP:
      return JQueryPlugin.REGION_MAP;
    case ComponentType.CHOROPLETH_MAP: // legacy
      return JQueryPlugin.REGION_MAP;
    case ComponentType.CALENDAR:
    case ComponentType.COLUMN_CHART:
    case ComponentType.SCATTER_CHART:
    case ComponentType.COMBO_CHART:
    case ComponentType.BAR_CHART:
    case ComponentType.PIE_CHART:
    case ComponentType.TIMELINE_CHART:
    case ComponentType.HISTOGRAM:
      return JQueryPlugin.INSITU;
    case ComponentType.TABLE:
    case ComponentType.AG_TABLE:
      return JQueryPlugin.TABLE;
    case ComponentType.FEATURE_MAP:
      return JQueryPlugin.FEATURE_MAP;
    case ComponentType.MAP:
      return JQueryPlugin.MAP;
    case ComponentType.VIZ_CANVAS:
      return JQueryPlugin.VIZ_CANVAS;
    case ComponentType.EMBEDDED_HTML:
      return JQueryPlugin.EMBEDDED_HTML;
    default:
      throw new Error(`No component renderer found for type: ${type}`);
  }
};
