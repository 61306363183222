import { cloneDeep, filter, isEmpty } from 'lodash';

import { GlobalParameterOverrides, GlobalFilters } from 'types';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import {
  DataSourceCCVs,
  DataSourceMetadata,
  FilterItemType,
  FilterParameterConfiguration,
  ParameterConfiguration,
  ReportDataSource,
  SingleDataSource
} from 'common/types/reportFilters';
import { Filters } from 'common/components/FilterBar/types';
import type { ClientContextVariable } from 'common/types/clientContextVariable';
import type { StorytellerState } from 'store/StorytellerReduxStore';
import { dataSourceReducerSelector } from 'store/TopLevelSelector';
import { groupClientContextVariablesByDataset } from 'common/components/FilterBar/lib/Filters';

export interface DataSourceReducerState {
  // Single source stories
  dataSource?: SingleDataSource;
  globalFilters?: {
    [datasetUid: string]: Filters;
  };

  // Multi source stories
  dataSourceList?: SingleDataSource[];
  filterConfigurations?: SoqlFilter[];
  filterParameterConfigurations?: FilterParameterConfiguration[];
  dataSourceMetadata?: DataSourceMetadata;
  parameters?: DataSourceCCVs;
  /** Data source uids */
  requiredDataSources?: string[];
  isNewRequiredSourcesAdded?: boolean;
  showLoadingIndicator?: boolean;
  filtersMigrated?: boolean;
}

/* Selectors */
export const selectors = {
  getDataSourceList: (state: any) => getDataSourceList(dataSourceReducerSelector(state)),
  getDataSourceUids: (state: any) => getDataSourceUids(dataSourceReducerSelector(state)),
  getReportDataSource: (state: any) => getReportDataSource(dataSourceReducerSelector(state)),
  getFilterParameterConfigurations: (state: any) =>
    getFilterParameterConfigurations(dataSourceReducerSelector(state)),
  getGlobalFilters: (state: StorytellerState) => getGlobalFilters(dataSourceReducerSelector(state)),
  getParameterOverrides: (state: StorytellerState) => getParameterOverrides(dataSourceReducerSelector(state)),
  getDataSourceMetadata: (state: StorytellerState) => getDataSourceMetadata(dataSourceReducerSelector(state)),
  getAllDataSourceCCVs: (state: StorytellerState) => getAllDataSourceCCVs(dataSourceReducerSelector(state)),
  getDataSourceCCVs: (state: StorytellerState) => getDataSourceCCVs(dataSourceReducerSelector(state)),
  getRequiredDataSources: (state: StorytellerState) =>
    getRequiredDataSources(dataSourceReducerSelector(state)),
  getDataSourceForSerialize: (state: StorytellerState) =>
    getDataSourceForSerialize(dataSourceReducerSelector(state)),
  getIsNewRequiredSourcesAdded: (state: StorytellerState) =>
    getIsNewRequiredSourcesAdded(dataSourceReducerSelector(state)),
  getShowLoadingIndicator: (state: StorytellerState) =>
    getShowLoadingIndicator(dataSourceReducerSelector(state)),
  hasEmptyDataSource: (state: StorytellerState) => hasEmptyDataSource(dataSourceReducerSelector(state))
};

const getDataSourceUids = (state: DataSourceReducerState) => {
  return getDataSourceList(state).map(({ datasetUid }) => datasetUid);
};

const getDataSourceList = (state: DataSourceReducerState): SingleDataSource[] => {
  const { dataSourceList = [] } = (getReportDataSource(state) || {}) as ReportDataSource;
  return dataSourceList;
};

const getReportDataSource = (state: DataSourceReducerState) => {
  const dataSource: ReportDataSource = {
    dataSourceList: state.dataSourceList ?? []
  };

  if (state.hasOwnProperty('filterParameterConfigurations')) {
    dataSource.filterParameterConfigurations = state.filterParameterConfigurations;
  }

  return dataSource;
};

const getFilterParameterConfigurations = (state: DataSourceReducerState): FilterParameterConfiguration[] => {
  const { filterParameterConfigurations = [] } = state;
  return filterParameterConfigurations;
};

const getGlobalFilters = (state: DataSourceReducerState): GlobalFilters => {
  // Set up GlobalFilters using filterParameterConfigurations
  return getGlobalFiltersFromFilterParameterConfigurations(state.filterParameterConfigurations ?? []);
};

const getParameterOverrides = (state: DataSourceReducerState): GlobalParameterOverrides => {
  if (state.filterParameterConfigurations?.length) {
    const parameterConfigurations = state.filterParameterConfigurations
      .filter(({ type }) => type === FilterItemType.PARAMETER)
      .map(({ config }) => config as ParameterConfiguration);
    return groupClientContextVariablesByDataset(parameterConfigurations);
  }

  return {};
};

const getDataSourceForSerialize = (state: DataSourceReducerState) => {
  const dataSource = getReportDataSource(state);

  if (hasEmptyDataSource(state)) {
    return undefined;
  }
  return dataSource;
};

const getRequiredDataSources = (state: DataSourceReducerState) => state.requiredDataSources || [];
const getDataSourceMetadata = (state: DataSourceReducerState) => {
  const applicableMetadata: DataSourceMetadata = {};
  const allMetadata = state.dataSourceMetadata ?? {};
  const dataSources = getDataSourceUids(state);
  dataSources.forEach((datasetUid) => {
    if (allMetadata[datasetUid]) {
      applicableMetadata[datasetUid] = allMetadata[datasetUid];
    }
  });
  return applicableMetadata;
};

const getAllDataSourceCCVs = (state: DataSourceReducerState) => {
  const allParameters = state.parameters ?? {};
  const dataSources = getDataSourceUids(state);

  const availableParameters = Object.keys(allParameters)
    .filter((datasetUid) => dataSources.includes(datasetUid))
    .reduce((acc, datasetUid) => {
      acc[datasetUid] = allParameters[datasetUid];
      return acc;
    }, {});

  return availableParameters;
};

const getDataSourceCCVs = (state: DataSourceReducerState) => {
  const applicableParameters: ClientContextVariable[] = [];
  const allParameters = state.parameters ?? {};
  const dataSources = getDataSourceUids(state);
  dataSources.forEach((datasetUid) => {
    if (allParameters[datasetUid]) {
      applicableParameters.push(...allParameters[datasetUid]);
    }
  });
  return applicableParameters;
};

const getIsNewRequiredSourcesAdded = (state: DataSourceReducerState) =>
  state.isNewRequiredSourcesAdded || false;

const getShowLoadingIndicator = (state: DataSourceReducerState) => state.showLoadingIndicator || false;

const hasEmptyDataSource = (state: DataSourceReducerState) => {
  return isEmpty(state.dataSourceList);
};

const getGlobalFiltersFromFilterParameterConfigurations = (
  filterParameterConfigurations: FilterParameterConfiguration[]
): GlobalFilters => {
  if (isEmpty(filterParameterConfigurations)) {
    return {};
  }
  const globalFilters: GlobalFilters = {};
  filterParameterConfigurations.forEach((filterParameterConfig) => {
    if (filterParameterConfig.type === FilterItemType.FILTER) {
      const { columns } = filterParameterConfig.config;
      columns.forEach((column) => {
        const datasetUid = column.datasetUid;
        const vifFilter = cloneDeep({
          ...filterParameterConfig.config,
          columns: filter(
            filterParameterConfig.config.columns,
            (filterColumn) => filterColumn.datasetUid === datasetUid
          )
        }) as SoqlFilter;

        if (Array.isArray(globalFilters[datasetUid])) {
          (globalFilters[datasetUid] as SoqlFilter[]).push(vifFilter as SoqlFilter);
        } else {
          globalFilters[datasetUid] = [vifFilter] as SoqlFilter[];
        }
      });
    }
  });
  return globalFilters;
};
