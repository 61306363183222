import { cloneDeep } from 'lodash';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { DataSourceCCVs, ParameterConfiguration } from 'common/types/reportFilters';
import { SpecificValue, SuggestedValuesType } from 'common/types/clientContextVariable';
import FeatureFlags from 'common/feature_flags';

/**
 * Given a list of all the client context variables, add the overrides to each one.
 */
export const mergeClientContextVariables = (
  overrides: ClientContextVariable[],
  all: ClientContextVariable[]
): ClientContextVariable[] => {
  return all.map((variable) => {
    const override = overrides.find(
      ({ name, viewId }) => name === variable.name && viewId === variable.viewId
    );
    if (override?.overrideValue && isOverrideValid(variable, override?.overrideValue)) {
      return { ...variable, overrideValue: override.overrideValue };
    }
    return variable;
  });
};

/**
 * Check the override to see if it is valid. For client context variables with SPECIFIC_VALUES,
 * the override is valid if it is in the suggested values list. For client context variables
 * with ANY_VALUES, all overrides are valid.
 */
const isOverrideValid = (variable: ClientContextVariable, override: string) => {
  const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
    'enable_parameter_dropdowns_in_stories',
    false
  );
  const compareValueOnly = (a: SpecificValue) => {
    return a.value === override;
  };
  if (
    enableParameterDropdownsInStories &&
    variable.suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES &&
    !variable?.suggestedValues?.valueList.find(compareValueOnly)
  ) {
    return false;
  }
  return true;
};

/**
 * If the client context variable has SPECIFIC_VALUES, then make sure the parameter configuration's
 * override in in the array of suggested values. If it's not in the list, change the override to the
 * default value for the client context variable.
 */
export const migrateParameter = (
  parameterConfig: ParameterConfiguration,
  clientContextVariables: DataSourceCCVs
) => {
  const migratedParameterConfig = cloneDeep(parameterConfig);
  const dataSourceCCVs = clientContextVariables[parameterConfig.paramIds[0].datasetUid];
  const ccvForParameter = dataSourceCCVs.find((ccv) => ccv.name === parameterConfig.paramIds[0].name);
  if (ccvForParameter && !isOverrideValid(ccvForParameter, parameterConfig.overrideValue)) {
    return { ...migratedParameterConfig, overrideValue: ccvForParameter.defaultValue };
  }
  return migratedParameterConfig;
};
