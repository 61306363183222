import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { isEqual, set, cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Resizable } from 'react-resizable';

import Environment from 'StorytellerEnvironment';
import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { selectors as actionComponentSelectors } from 'store/selectors/ActionComponentSelectors';
import Actions from 'Actions';
import { dispatcher } from 'Dispatcher';
import { JQueryPlugin, BlockComponent } from 'types';
import Constants from 'lib/Constants';

import ComponentEditControls from 'editor/components/ComponentEditControls';
import { shouldUseReactComponentBase, isFlexibleStory } from 'lib/FlexibleLayoutUtils';
import { runJQueryPlugin, socrataVizInvalidateSizeHelper } from 'editor/renderers/SharedRenderingUtils';

import {
  getComponentRenderer,
  getResizableComponentProps
} from 'lib/components/block-component-renderers/shared/BlockComponentRendererReactLiaison';

export interface StoryComponentRendererProps {
  blockId: string;
  componentIndex: number;
  additionalClasses?: string;
  // This is only used for view mode
  editMode: boolean;
}

const StoryComponentRenderer = forwardRef<HTMLDivElement, StoryComponentRendererProps>(
  ({ blockId, componentIndex, additionalClasses = '', editMode }, ref) => {
    const componentRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => componentRef.current!, []);

    const theme = useSelector((state: StorytellerState) =>
      storySelectors.getStoryTheme(state, Environment.STORY_UID!)
    );
    const additionalFilters = useSelector(dataSourceSelectors.getGlobalFilters, isEqual);
    const parameterOverrides = useSelector(dataSourceSelectors.getParameterOverrides, isEqual);
    const componentData = useSelector((state: StorytellerState) => {
      return storySelectors.getBlockComponentAtIndex(state, blockId, componentIndex);
    }, isEqual);

    useSelector(actionComponentSelectors.isActionComponentStoreActive);

    const jQueryRendererToRun = getComponentRenderer(componentData.type);
    const currentRenderer = useRef<JQueryPlugin | null>(null);

    const jQueryResizableProps = getResizableComponentProps(jQueryRendererToRun, componentData);
    // When a componentHero gets an image, it suddenly becomes resizable and that messes with the DOM.
    // CBR-TODO: This is starting to feel too coupled. Not sure how to solve it yet,
    // but just acknowledging the code smell.
    const currentResizeSupported = useRef(jQueryResizableProps.resizeSupported);

    const componentEditControlsProps = {
      blockId,
      componentIndex,
      componentData,
      editMode
    };

    const pluginProps = useMemo(
      () => ({
        componentData,
        theme,
        editMode,
        blockId,
        componentIndex,
        additionalFilters,
        parameterOverrides
      }),
      [componentData, theme, editMode, blockId, componentIndex, additionalFilters, parameterOverrides]
    );

    // render the jQuery plugin on the ref
    useEffect(() => {
      if (componentRef?.current) {
        // Skip asset selectors in view mode
        if (!editMode && jQueryRendererToRun === JQueryPlugin.ASSET_SELECTOR) return;

        const needToChangeRenderer =
          currentRenderer.current !== jQueryRendererToRun ||
          (shouldUseReactComponentBase() &&
            currentResizeSupported.current !== jQueryResizableProps.resizeSupported); // For hero components
        if (needToChangeRenderer) {
          currentRenderer.current = jQueryRendererToRun;
          currentResizeSupported.current = jQueryResizableProps.resizeSupported;
        }
        runJQueryPlugin(componentRef.current, needToChangeRenderer, jQueryRendererToRun, pluginProps);
      }
      // No skipping renders. This will run any time componentData changes, including every keypress
      // According to my current performance testing, this is no longer slow.
    }, [jQueryRendererToRun, pluginProps, editMode, jQueryResizableProps]);

    const renderComponentContainer = () => {
      return (
        <div
          className={`component-container ${shouldUseReactComponentBase(editMode) ? '' : additionalClasses}`}
          ref={componentRef}
          data-component-index={componentIndex}
          data-component-renderer-name={jQueryRendererToRun}
        />
      );
    };

    // Regardless of classic/flex, if the flag is off or we're in view mode, use CBJ only
    if (!shouldUseReactComponentBase(editMode)) {
      return renderComponentContainer();
    }

    const renderResizableComponentContainer = () => {
      return (
        <ComponentResizable
          ref={componentRef}
          componentData={componentData}
          blockId={blockId}
          componentIndex={componentIndex}
        />
      );
    };

    return (
      <div
        className={`component-base-container ${additionalClasses}`}
        data-component-renderer-name={jQueryRendererToRun}
      >
        <ComponentEditControls {...componentEditControlsProps} />
        {jQueryResizableProps.resizeSupported && !isFlexibleStory()
          ? renderResizableComponentContainer()
          : renderComponentContainer()}
      </div>
    );
  }
);

export default StoryComponentRenderer;

interface ComponentResizableProps {
  componentData: BlockComponent;
  blockId: string;
  componentIndex: number;
}

const ComponentResizable = forwardRef<HTMLDivElement, ComponentResizableProps>(
  ({ componentData, blockId, componentIndex }, ref) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const jQueryRendererToRun = getComponentRenderer(componentData.type);
    const jQueryResizableProps = useMemo(
      () => getResizableComponentProps(jQueryRendererToRun, componentData),
      // returns a new object every time, so we need to memoize it for the invalidate size useEffect
      [jQueryRendererToRun, componentData]
    );

    useImperativeHandle(ref, () => componentRef.current!, []);

    const [height, setHeight] = useState<number>(
      componentData.value?.layout?.height ||
        jQueryResizableProps.defaultHeight ||
        // EN-71068: most vizes do not use this height, and have different jQuery dependant behavior.
        // That behavior is hard to replicate in React, so we're using the default height for now.
        Constants.DEFAULT_VISUALIZATION_HEIGHT
    );

    // Send SOCRATA_VISUALIZATION_INVALIDATE_SIZE event when height changes, with jQuery helper
    useEffect(() => {
      if (shouldUseReactComponentBase() && jQueryResizableProps.resizeSupported && componentRef?.current) {
        socrataVizInvalidateSizeHelper(componentRef.current);
      }
    }, [jQueryResizableProps, height]);

    const onResize = (event: React.SyntheticEvent, { size }: { size: { width: number; height: number } }) => {
      // Update local state
      setHeight(size.height);

      // Update redux store
      const updatedComponentData = cloneDeep(componentData);
      set(updatedComponentData, 'value.layout.height', size.height);

      dispatcher.dispatch({
        action: Actions.BLOCK_UPDATE_COMPONENT,
        blockId: blockId,
        componentIndex: componentIndex,
        type: updatedComponentData.type,
        value: updatedComponentData.value
      });
    };

    return (
      <Resizable
        height={height}
        width={100} // Width doesn't matter since we're not resizing horizontally
        minConstraints={[100, jQueryResizableProps.resizeOptions?.minHeight || 0]}
        axis="y" // Only allow vertical resizing
        onResize={onResize}
        resizeHandles={['s']}
        handle={
          <div className="component-resize-handle">
            <div></div>
          </div>
        }
      >
        <div>
          <div
            className={'component-container'}
            ref={componentRef}
            data-component-index={componentIndex}
            data-component-renderer-name={jQueryRendererToRun}
            style={{ height: height + 'px' }}
          />
        </div>
      </Resizable>
    );
  }
);
