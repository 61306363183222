import React, { FunctionComponent } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { sharedFlexRGLProps } from 'lib/FlexibleLayoutUtils';
import ViewBlockItem from './ViewBlockItem';
import BlockItemModel from '../../editor/model/BlockItemModel';
import { BlockComponent } from 'types';
import { ComponentType } from 'types';
import { isPrintTableEnabled, getItemIdToPrint } from 'common/visualizations/helpers/AgGridPrintHelper';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';

export interface ViewBlockSectionProps {
  components: BlockComponent[];
  blockId: string;
}

// @ts-expect-error TS(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const ResponsiveReactGridLayout = new WidthProvider(Responsive);

const ViewBlockSection: FunctionComponent<ViewBlockSectionProps> = (props) => {
  const { blockId, components } = props;

  const backgroundColor = useSelector((state: StorytellerState) =>
    storySelectors.getBlockBackgroundColor(state, blockId)
  );

  const isPresentable = useSelector((state: StorytellerState) =>
    storySelectors.isBlockPresentable(state, blockId)
  );

  // TODO_PRINT_TABLE: This print logic should work for all modes (flex/classic edit/view).
  // That should be easier in the future with the new shared Renderer component.

  // This id will be set by SourceDataOptions, using the `data-item-id` attribute we set below.
  // If it's set, it means we went from normal view mode to print mode.
  const itemIdToPrint = getItemIdToPrint();
  const printTableEnabled = itemIdToPrint && isPrintTableEnabled();

  const generateBlockItemModels = (blockItemData: BlockComponent[]): BlockItemModel[] => {
    return blockItemData.map((blockItem, index: number) => {
      return new BlockItemModel(blockItem.layout, blockItem.type, blockItem.value, index, blockId);
    });
  };

  // Applies default layouts and ids to components
  const rawItems = generateBlockItemModels(components);

  const blockItems = printTableEnabled
    ? rawItems.filter((blockItem: BlockItemModel) => blockItem.layout.i === itemIdToPrint)
    : rawItems;

  const renderBlockSectionItem = () => {
    // static must be set at the end of object because JS is dumb
    const dataGridLayout = (blockItem: BlockItemModel) => ({
      ...blockItem.layout,
      isDraggable: false,
      static: true
    });

    return blockItems.map((blockItem: BlockItemModel, index: number) => {
      const layout = { ...blockItem.layout };

      if (printTableEnabled) {
        // This means we've filtered to the single table we want to print, and need a few adjustments.
        // TODO_PRINT_TABLE: There are cases when not all columns are visible. This might be intractable?
        // The user can fiddle with their personalized story, or even in the print preview.
        // But it still feels like there should be some options.
        layout.y = 0;
        layout.x = 0;
        // We might want to enable this? The user can make columns within the table smaller, but they can't
        // make the table itself larger. The only reason not to do this is that it might be confusing why
        // a table that wasn't full width suddenly has weird columns.
        // layout.w = 12;
        layout.moved = true; // Not sure what this does actually...
        blockItem.layout = layout;
      }

      return (
        <div key={blockItem.layout.i} data-item-id={blockItem.layout.i} data-grid={dataGridLayout(blockItem)}>
          <ViewBlockItem
            blockId={blockId}
            componentIndex={index}
            component={{
              id: blockItem.id,
              type: blockItem.type,
              value: blockItem.value,
              layout: blockItem.layout
            }}
          />
        </div>
      );
    });
  };

  const isGlobalFilter = components[0].type === ComponentType.GLOBAL_FILTER;
  const blockClasses = classNames('block', {
    sticky: isGlobalFilter
  });

  return (
    <div
      className={blockClasses}
      style={{ backgroundColor }}
      data-block-id={blockId}
      data-presentable={isPresentable}
    >
      <div className="block-content">
        {components[0].type === ComponentType.GLOBAL_FILTER ? (
          renderBlockSectionItem()
        ) : (
          <ResponsiveReactGridLayout
            className={sharedFlexRGLProps.className}
            cols={sharedFlexRGLProps.cols}
            rowHeight={sharedFlexRGLProps.rowHeight}
            isDraggable={false}
            isResizable={false}
            isDroppable={false}
            containerPadding={sharedFlexRGLProps.containerPadding}
            margin={sharedFlexRGLProps.margin}
            // setting this to false forces D3 to not rely on `transform` for positioning.
            // if we don't set it to false, the location of `d3.mouse` will somehow return
            // the wrong cursor location.
            // see https://github.com/elastic/kibana/issues/16870 for a similar issue.
            // useCSSTransforms={false}
          >
            {renderBlockSectionItem()}
          </ResponsiveReactGridLayout>
        )}
      </div>
    </div>
  );
};

export default ViewBlockSection;
