import $ from 'jquery';
import _ from 'lodash';

import { assert, assertHasProperty } from 'common/assertions';
import parseJsonOrEmpty from 'common/js_utils/parseJsonOrEmpty';

import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { updateFeatureMapVifWithDefaults, updateVifWithDefaults, vifsAreEquivalent } from 'lib/VifUtils';
import { flyoutRenderer } from 'lib/components/FlyoutRenderer';

import './shared/componentBase';
import { FeatureMapProps, FlyoutEvent } from './types';
import { FeatureMapBlockComponent } from 'types';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

$.fn.componentSocrataVisualizationFeatureMap = componentSocrataVisualizationFeatureMap;

/*
  Component format:
  {
   type: "socrata.visualization.featureMap",
   value: {
     vif: <vif object>
   }
  }
*/

export const resizableProps = () => {
  return {
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    }
  };
};

export default function componentSocrataVisualizationFeatureMap(props: FeatureMapProps) {
  props = _.extend({}, props, resizableProps());

  const $this = $(this);
  const { componentData, editMode } = props;

  assertHasProperty(props, 'componentData.type');
  assert(
    props.componentData.type === 'socrata.visualization.featureMap',
    `componentSocrataVisualizationFeatureMap: Unsupported component type ${props.componentData.type}`
  );

  if ($this.children().length === 0) {
    _renderTemplate($this, componentData);
  }

  _updateVisualization($this, props);

  if (!shouldUseReactComponentBase(editMode)) {
    $this.componentBase(props);
  }

  return $this;
}

function _renderTemplate($element: JQuery, componentData: FeatureMapBlockComponent) {
  assertHasProperty(componentData, 'type');

  const classes = StorytellerUtils.typeToClassesForComponentType(componentData.type);
  const $componentContent = $('<div>', { class: 'component-content' });
  const flyoutEvent = 'SOCRATA_VISUALIZATION_FLYOUT';

  $element
    .addClass(classes)
    .on('destroy', () => {
      $componentContent.triggerHandler('destroy');
    })
    .on(flyoutEvent, (e: JQuery.Event) => {
      const event = e as FlyoutEvent;
      const payload = event.originalEvent.detail;

      if (payload !== null) {
        flyoutRenderer.render(payload);
      } else {
        flyoutRenderer.clear();
      }
    });

  $element.append($componentContent);
}

function _updateVisualization($element: JQuery, props: FeatureMapProps) {
  assertHasProperty(props, 'componentData.value.vif');

  const { componentData } = props;
  const renderedVif = $element.attr('data-rendered-vif');
  const $componentContent = $element.find('.component-content');
  let { vif } = componentData.value;

  const areNotEquivalent = !vifsAreEquivalent(parseJsonOrEmpty(renderedVif), vif);
  if (areNotEquivalent) {
    $element.attr('data-rendered-vif', JSON.stringify(vif));
    vif = updateVifWithDefaults(vif);
    vif = updateFeatureMapVifWithDefaults(vif);

    // EN-7517 - Title and description of VisualizationAddController V1 vifs are not useful.
    //
    // The new viz implementations actually read from title and description and
    // will display them, but the VisualizationAdd controller will set the
    // title to the name of the column or something.
    if (_.get(vif, 'format.version') === 1) {
      vif.title = undefined;
      vif.description = undefined;
    }

    // If it looks like the map has already been rendered once, we can just update it instead of
    // destroying it and rendering from scratch
    const isFeatureMap = _.isEqual(_.get(vif, 'series[0].type'), 'featureMap');
    if (isFeatureMap && $componentContent.find('canvas').length > 0) {
      $componentContent[0].dispatchEvent(
        new CustomEvent('SOCRATA_VISUALIZATION_RENDER_VIF', { detail: vif })
      );
      // Otherwise, we should destroy whatever used to be in the component and
      // create a new feature map in its place.
    } else {
      // Use triggerHandler since we don't want this to bubble
      $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
      $componentContent.socrataSvgFeatureMap(vif, { displayFilterBar: true });
    }
  }
}
