import React, { useEffect, useState, useMemo } from 'react';
import set from 'lodash/set';
import find from 'lodash/find';

import { assertHasProperty } from 'common/assertions';
import { Vif } from 'common/visualizations/vif';
import HTTPError from 'common/errors/HTTPError';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';
import { View } from 'common/types/view';

import { VizCanvasDatasetConfig } from 'types';
import StoryVisualization from 'lib/components/StoryVisualization';

export interface ComponentVizCanvasProps {
  visualizationConfig: VizCanvasDatasetConfig;
  useMetadataCache?: boolean;
  jQueryErrorCallback: (error: boolean) => void;
}

const findMatchingVifInView = (view: View, vifId: string): Vif => {
  assertHasProperty(view, 'displayFormat.visualizationCanvasMetadata.vifs');
  const vifs = view.displayFormat?.visualizationCanvasMetadata?.vifs;
  const newVif = find(vifs, { id: vifId }) as Vif;

  // Core's serialization strips out any properties that have null or "empty"
  // values. The vif has properties whose values are intentionally null, not including
  // them will prevent the visualization from rendering. We handle this for embeds
  // and Viz Cans in frontend/app/models/display_format.rb#restore_required_vif_metadata
  const measure = newVif.series[0].dataSource.measure;
  if (measure) {
    measure.aggregationFunction ||= null;
  }

  return newVif;
};

const getVif = async (config: VizCanvasDatasetConfig, metadataProvider: MetadataProvider): Promise<Vif> => {
  const { datasetUid, vifId } = config;
  const datasetMetadataAndFederationStatus = await metadataProvider.getDatasetMetadataAndFederationStatus();
  const view = datasetMetadataAndFederationStatus.metadata;
  const newVif = findMatchingVifInView(view, vifId);
  if (!newVif) {
    throw new HTTPError(404, `Could not find VIF with id ${vifId} in the view.`);
  }

  // Set vif.origin.url to point to the vizcan asset on the appropriate domain
  const attributionDomain = await metadataProvider.getAttributionDomain(datasetMetadataAndFederationStatus);
  if (attributionDomain) {
    set(newVif, 'origin.url', `https://${attributionDomain}/d/${datasetUid}`);
    set(newVif, 'origin.title', view.name);
  }

  return newVif;
};

const ComponentVizCanvas: React.FC<ComponentVizCanvasProps> = ({
  visualizationConfig,
  useMetadataCache = true,
  jQueryErrorCallback
}) => {
  const [vif, setVif] = useState<Vif | null>(null);
  const metadataProvider = useMemo(
    () => new MetadataProvider({ datasetUid: visualizationConfig.datasetUid }, useMetadataCache),
    [visualizationConfig, useMetadataCache]
  );

  useEffect(() => {
    getVif(visualizationConfig, metadataProvider)
      .then((newVif: Vif) => {
        jQueryErrorCallback(false);
        setVif(newVif);
      })
      .catch(() => {
        jQueryErrorCallback(true);
      });
  }, [visualizationConfig, metadataProvider, jQueryErrorCallback]);

  const additionalOptions = { displayFilterBar: true, toggleMapLayersInternally: true };
  if (vif) {
    return <StoryVisualization storyVif={vif} options={additionalOptions} />;
  } else {
    return null;
  }
};

export default ComponentVizCanvas;
